var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "kt-container kt-grid__item kt-grid__item--fluid w-100" },
    [
      _c(
        "div",
        {
          staticClass:
            "kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app pr-4",
        },
        [
          _c(
            "div",
            {
              staticClass: "kt-grid__item kt-grid__item--fluid kt-app__content",
            },
            [
              _c("div", { staticClass: "kt-portlet kt-portlet--tabs" }, [
                _c("div", { staticClass: "kt-portlet__head" }, [
                  _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
                    _c(
                      "ul",
                      {
                        staticClass:
                          "nav nav-tabs nav-tabs-space-xl nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand",
                      },
                      [
                        _c(
                          "li",
                          { staticClass: "nav-item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "nav-link pr-4 pl-2",
                                class: {
                                  active: _vm.$route.name == "AccountSessions",
                                },
                                attrs: { to: { name: "AccountSessions" } },
                              },
                              [
                                _c("SVGIcon", { attrs: { name: "sessions" } }),
                                _vm._v(" Sessions "),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        !["District Managed", "Disabled"].includes(
                          _vm.user.school.googleSetting
                        ) &&
                        !_vm.$_userMixins_isSuperAdmin &&
                        _vm.$_userMixins_isSchoolUser
                          ? _c(
                              "li",
                              { staticClass: "nav-item" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "nav-link pr-4 pl-2",
                                    class: {
                                      active: _vm.$route.name == "MyProfile",
                                    },
                                    attrs: { to: { name: "MyProfile" } },
                                  },
                                  [
                                    _c("SVGIcon", {
                                      attrs: { name: "account" },
                                    }),
                                    _vm._v(" Account "),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "li",
                          { staticClass: "nav-item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "nav-link pr-4 pl-2",
                                class: {
                                  active: _vm.$route.name == "AccountSecurity",
                                },
                                attrs: { to: { name: "AccountSecurity" } },
                              },
                              [
                                _c("SVGIcon", { attrs: { name: "lock" } }),
                                _vm._v(" Security "),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.canAccessBilling
                          ? _c(
                              "li",
                              { staticClass: "nav-item" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "nav-link pr-4 pl-2",
                                    class: {
                                      active: _vm.$route.name == "Billing",
                                    },
                                    attrs: { to: { name: "Billing" } },
                                  },
                                  [
                                    _c("SVGIcon", {
                                      attrs: { name: "billing" },
                                    }),
                                    _vm._v(" Billing "),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "li",
                          { staticClass: "nav-item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "nav-link pr-4 pl-2",
                                class: {
                                  active: _vm.$route.name == "Password",
                                },
                                attrs: { to: { name: "Password" } },
                              },
                              [
                                _c("SVGIcon", { attrs: { name: "password" } }),
                                _vm._v(" Password "),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "li",
                          { staticClass: "nav-item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "nav-link pr-4 pl-2",
                                class: {
                                  active:
                                    _vm.$route.name == "CommunicationSettings",
                                },
                                attrs: {
                                  to: { name: "CommunicationSettings" },
                                },
                              },
                              [
                                _c("SVGIcon", {
                                  attrs: { name: "CommunicationSettings" },
                                }),
                                _vm._v(" Communication "),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "kt-portlet__body" }, [
                  _c("div", { staticClass: "tab-content" }, [
                    _c(
                      "div",
                      { staticClass: "tab-pane active" },
                      [_c("router-view")],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }