<template>
<div class="kt-container kt-grid__item kt-grid__item--fluid w-100">
    <div class="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app pr-4">
        <div class="kt-grid__item kt-grid__item--fluid kt-app__content">
            <div class="kt-portlet kt-portlet--tabs">
                <div class="kt-portlet__head">
                    <div class="kt-portlet__head-toolbar">
                        <ul class="nav nav-tabs nav-tabs-space-xl nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand">
                            <li class="nav-item">
                                <router-link
                                    class="nav-link pr-4 pl-2"
                                    :class="{ active: $route.name == 'AccountSessions' }"
                                    :to="{ name: 'AccountSessions' }"
                                >
                                    <SVGIcon name="sessions" /> Sessions
                                </router-link>
                            </li>
                            <li
                                v-if="!['District Managed', 'Disabled'].includes(user.school.googleSetting) && !$_userMixins_isSuperAdmin && $_userMixins_isSchoolUser"
                                class="nav-item"
                            >
                                <router-link
                                    class="nav-link pr-4 pl-2"
                                    :class="{ active: $route.name == 'MyProfile' }"
                                    :to="{ name: 'MyProfile' }"
                                >
                                    <SVGIcon name="account" /> Account
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link
                                    class="nav-link pr-4 pl-2"
                                    :class="{ active: $route.name == 'AccountSecurity' }"
                                    :to="{ name: 'AccountSecurity' }"
                                >
                                    <SVGIcon name="lock" /> Security
                                </router-link>
                            </li>
                            <li
                                v-if="canAccessBilling"
                                class="nav-item"
                            >
                                <router-link
                                    class="nav-link pr-4 pl-2"
                                    :class="{ active: $route.name == 'Billing' }"
                                    :to="{ name: 'Billing' }"
                                >
                                    <SVGIcon name="billing" /> Billing
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link
                                    class="nav-link pr-4 pl-2"
                                    :class="{ active: $route.name == 'Password' }"
                                    :to="{ name: 'Password' }"
                                >
                                    <SVGIcon name="password" /> Password
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link
                                    class="nav-link pr-4 pl-2"
                                    :class="{ active: $route.name == 'CommunicationSettings' }"
                                    :to="{ name: 'CommunicationSettings' }"
                                >
                                    <SVGIcon name="CommunicationSettings" /> Communication
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="kt-portlet__body">
                    <div class="tab-content">
                        <div class="tab-pane active">
                            <router-view />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import userMixins from '../../store/mixins/userMixins';

export default {
    name: 'AccountSetup',
    mixins: [userMixins],
    computed: {
        ...mapState({
            user: (state) => state.user,
            isSuperAdmin: (state) => state.user.isSuperAdmin,
            userPermissions: (state) => state.user.userPermissions,
        }),
        canAccessBilling() {
            return this.isSuperAdmin || (this.userPermissions.canManagePurchaseOrders && this.$_userMixins_isSchoolUser);
        },
    },
};
</script>
